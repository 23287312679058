<style lang="scss">
@import "assets/scss/components/navbar.scss";
</style>

<template>
	<div class="navbar" :expanded="expend" @mouseover="mouseOver" @mouseleave="mouseLeave">
		<nav class="nav">
			<div class="top nav_list">
				<NuxtLink v-for="link in navLinks" :key="link.url" :to="link.url" class="nav_link"
				          exact-active-class="active">
					<i :class="link.icon"></i>
					<span class="nav_name">{{ link.name }}</span>
				</NuxtLink>
			</div>
			<div class="bottom flex items-center justify-between w-full" v-if="$user">
				<UDropdown :items="items">
					<VsAvatar :name="$user.firstname + ' ' +$user.lastname" badge badgeColor="success"
					          class="cursor-pointer" size="50"></VsAvatar>
					<template #account="{ item }">
						<div class="text-left">
							<p>
								Connecté.e en tant que
							</p>
							<p class="truncate font-medium text-gray-900 dark:text-white">
								{{ $user.email }}
							</p>
						</div>
					</template>

					<template #item="{ item }">
						<span class="truncate">{{ item.label }}</span>

						<UIcon :name="item.icon"
						       class="flex-shrink-0 h-4 w-4 text-gray-400 dark:text-gray-500 ms-auto"/>
					</template>
				</UDropdown>
			</div>
		</nav>
	</div>
</template>
<script>
import { VsAvatar } from "vuesax-ts";
import { logout } from "~/utils/helper";
import { feedbackIntegration } from "@sentry/vue";

export default {
	name: "Navbar",
	components: {
		VsAvatar
	},
	props: {
		navLinks: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			expend: false,
			timeouts: {},
			printer: null,
			maker: {
				white_label: false
			},
			items: [[{
				slot: "account",
				disabled: true
			}], [{
				label: "Mon compte",
				icon: "i-heroicons-cog-8-tooth",
				click: () => {
					navigateTo("/settings/user");
				}
			}], [{
        label: "Déclarer un bug",
        icon: "i-heroicons-information-circle",
        click: async () => {
          const { $user } = useNuxtApp();
          const feedback = feedbackIntegration({
            autoInject: false,
            useSentryUser: {
              email: $user.email,
              username: $user.firstname + " " + $user.lastname
            },
            showName: false,
            showEmail: false
          });
          const form = await feedback.createForm();
          form.appendToDom();
          form.open();
        }
      }], [{
				label: "Déconnexion",
				icon: "i-heroicons-arrow-left-on-rectangle",
				click: () => {
					logout();
				}
			}]]
		};
	},
	mounted() {
    const { $user } = useNuxtApp();
		if ($user?.isAdmin) {
			const route = useRoute();
			if (route.path.startsWith("/admin")) {
				this.items[1].unshift({
					label: "Interface utilisateur",
					icon: "i-heroicons-arrow-left-on-rectangle",
					click: () => {
						window.location.href = "/";
					}
				});
			} else {
				this.items[1].unshift({
					label: "Management",
					icon: "i-heroicons-arrow-left-on-rectangle",
					click: () => {
						window.location.href = "/admin";
					}
				});
			}
		}
	},
	methods: {
		mouseOver() {
			this.timeouts.expend = setTimeout(() => {
				this.expend = true;
			}, 300);
		},

		mouseLeave() {
			clearTimeout(this.timeouts.expend);
			setTimeout(() => {
				this.expend = false;
			}, 300);
		}
	}
};
</script>
