<style lang="scss">
@import "assets/scss/components/header.scss";
</style>

<template>
	<header>
		<div class="left">
			<img src="/images/logo.png" class="h-full" alt="Logo de la marque">
		</div>
		<div class="right">
			<div class="dropdown">
				<UDropdown :items="items" :popper="{ placement: 'bottom-start' }">
					<vs-button class="dropdown__button">
						<i class="isax isax-buildings"></i>
						<span>{{ account.displayname }}</span>
						<i class="isax isax-arrow-down-1 arrow"></i>
					</vs-button>
				</UDropdown>
			</div>
		</div>
	</header>
</template>

<script setup>
import { VsButton } from "vuesax-ts";
import { text2Price } from "../utils/global.mjs";
</script>

<script>
import { fetch } from "~/utils/helper";

export default {
	name: "Header",
	data() {
		return {
			printer: null,
			account: {},
			user: {
				role: 5,
				isAdmin: true
			},
			fetchingAccounts: false,
			items: [
				[{
					label: "Changer de compte",
					icon: "i-heroicons-arrows-right-left-20-solid",
					click: async () => {
						await this.switchAccount();
					}
				}],
			]
		};
	},
	async beforeMount() {
		const { $user, $account } = useNuxtApp();
		this.user = $user;
		this.account = $account;
		console.log($user.permissions)
		if ($user.permissions?.account?.view || $user.permissions?.account?.payments || $user.permissions?.users?.view) {
			let adminItems = [];
			if ($user.permissions?.account?.view) {
				adminItems.push({
					label: "Mon entreprise",
					icon: "i-heroicons-building-office-2-20-solid",
					click: () => {
						navigateTo("/settings/account");
					}
				});
			}
			if ($user.permissions?.account?.payments) {
				adminItems.push({
					label: "Mon offre",
					icon: "i-heroicons-credit-card-20-solid",
					click: () => {
						navigateTo("/payments");
					}
				});
			}
			if ($user.permissions?.users?.view) {
				adminItems.push({
					label: "Utilisateurs",
					icon: "i-heroicons-users-20-solid",
					click: () => {
						navigateTo("/team");
					}
				});
			}
			this.items.push(adminItems);
		}
	},
	methods: {
		async switchAccount() {
			const context = this;
			const { $swal } = useNuxtApp();
			$swal.fire({
				title: "Chargement...",
				text: "Chargement des comptes...",
				showCancelButton: false,
				showConfirmButton: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
				loading: true,
				async didOpen(popup) {
					const accounts = await context.fetchAccounts();
					// transform accounts to { id: displayname }
					$swal.fire({
						title: "Changer de compte",
						text: "Choisissez un compte pour continuer",
						input: {
							label: "Vos comptes",
							type: "select",
							options: accounts.map(account => {
								return {
									value: account.id,
									label: account.displayname
								};
							}),
							value: context.account.id,
							validator: (value) => {
								if (!value) {
									return "Vous devez choisir un compte pour continuer";
								}
							},
						},
						showCancelButton: true,
						showConfirmButton: true,
						confirmButtonText: "Changer",
						cancelButtonText: "Annuler",
						showLoaderOnConfirm: true,
						async preConfirm(value) {
							const data = await fetch(`/accounts/@me`, {
								method: "POST",
								body: {
									accountId: value
								}
							});
							if (data.success) window.location.reload();
							else {
								$swal.fire({
									title: "Erreur",
									text: data.message,
									icon: "error"
								});
							}
						}
					});
				}
			});
		},
		async fetchAccounts() {
			const data = await fetch("/accounts/@me", {
				method: "GET"
			});
			return data.accounts;
		},
	}
};
</script>
